import {generate_checkbox_component_required} from './subschema/component_checkbox'

const schema_consent = {
  mandatory: true,
    format:{
        title: "Privacy Policy",
        description: ""
    },
    type: "section",
    entries: {
        consent: {
            format: {
              title: '',
              description: 'By checking the following checkbox, you consent to the collection and usage of your health data by Progenics Blood Cord Cryobank. We will not share your data with other parties'
            },
            type: "section",
            entries: {
              collection_consent: generate_checkbox_component_required('I consent to the collection and usage of my health data. '), 
              truthful_consent: generate_checkbox_component_required('I confirm that the health data entered is truthful.'),                                                           
              date: {
                label: 'Date signed',
                type: 'date',
                validation: {
                    required: 'Date is required',
                },
                width: "20"
              }
            }
        }
    }
}

export default schema_consent
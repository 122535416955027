import React from 'react'

import './StartPage.css'
import '../FormStructure/QuestionStyle.css'
const EmailVerification = (props) => {
    const [sendstate, setsendstate] = React.useState("unsent");
    const [iscorrect, setiscorrect] = React.useState("");



    function send_auto_email_request (data) {
        setsendstate("sending")
        fetch("https://infra-backend-gilt.vercel.app/app/" + "generate", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({email: data})
        })
        .then((res) => {
            return res.json()
        }).then((data) => {
          //  console.log(data)
            setsendstate(data.emailsent)
        })
        .catch((error)=>{
         //   console.log(error)
            setsendstate("network error")
        })
    }

    function check_auto_email_request(data, event) {
        event.preventDefault();
        fetch("https://infra-backend-gilt.vercel.app/app/" + "verify", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({email: data, code: event.target[0].value})
        })
        .then((res) => {
            return res.json()
        }).then((data) => {
          //  console.log(data)
            setiscorrect(data.valid)
            if (data.valid === 'valid code'){
               // console.log("CORRECT")
                props.forward(data.key);
            } else {
                setiscorrect("invalid code")
            }
        })
        .catch((error)=>{
//console.log(error)
            setiscorrect("network error")
        })
    }

   // /*
    React.useEffect(
        () => {      //  console.log("USEEFFECT TRIGGER")
        send_auto_email_request(props.data.schema_webpage.email)}
    , [])//*/


    function updateEmail(event) {
        event.preventDefault()
     //   console.log(event.target[0].value)
        props.updateData(event.target[0].value);
    }

    return (
        <div className = 'center'>
            <div className = 'colors outline column'>
                <div className = 'title'>Verify your Email</div>
                <div className = 'subtext'>
                    For verification purposes, we have sent a code to your previously specified email address, {props.data.schema_webpage.email}.
                    This email address will be used for future status updates and inquiries. 

                    Please enter your code below:
                </div>
                <div className='center wrap'>
                    <div className = 'column center segement'> 
                        <div>Email Status: {sendstate}</div>
                        <button className='stepbutton' onClick = {() => send_auto_email_request(props.data.schema_webpage.email)}>Resend Email</button>
                    </div>
                    <form className='column center segement' onSubmit={updateEmail}>
                        <div>Update your email address</div>
                        <div className='center'>
                            <input className='boxcolor halvedtextbox' id="newemail"></input>
                            <button className='stepbutton'>Update</button>
                        </div>
                    </form>
                    <form className='column center segement'  onSubmit = {(event) => check_auto_email_request(props.data.schema_webpage.email, event)}>
                        <div>Enter your verification code: </div>
                        <div className='center'>
                            <input className='boxcolor halvedtextbox' type="text"></input>
                            <button className='stepbutton'>Submit</button>
                        </div>
                        <div className='question-error'>{iscorrect}</div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default EmailVerification
import {generate_checkbox_component_required} from './subschema/component_checkbox'

const schema_learnmore = {
  mandatory: true,
    format:{
        title: "Email",
        description: "Please enter your contact information. A client care associate will respond within the next business day"
    },
    type: "section",
    entries: {
        firstName: {
            label: 'First Name',
            subtext: '(Required)',
            type: 'text',
            validation: {
              required: 'First Name is required',
            },
          },
          lastName: {
            label: 'Last Name',
            subtext: '(Required)',
            type: 'text',
            validation: {
              required: 'Last Name is required',
            },
          },
          email: {
            label: 'Email',
            subtext: '(Required)',
            type: 'email',
            validation: {
              required: 'Email is required',
              pattern: {
                value: /^\S+@\S+$/i,
                message: 'Invalid email address',
              },
            },
          },
          phoneNumber: {
            label: 'Phone Number',
            type: 'tel',
            validation: {
              pattern: {
                value: /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/i,
                message: 'Invalid phone number. Example phone number +99 (123) 222 3333 4444',
              },
            },
          },
          expectency: {
            label: 'Expected Due Date',
            type: 'date',
            validation: {
              pattern: {
              },
            },
            width: "20"
          },
          hospital: {
            label: 'Hospital',
            type: 'text',
            validation: {
              pattern: {
              },
            },
            width: "20"
          },
    }
}

export default schema_learnmore
import React from "react";
import "./StartPage.css"


const Error = (props) => {
    return (
        <div className = 'center'>
            <div className = 'colors outline column'>
                <div className = 'title'>Error</div>
                <div className = 'subtext'>
                    Your registration session has expired. Please restart your application.
                </div>
                <button className="nextstepbutton" onClick = {() => {props.callback()}}>Start another registration</button>
            </div>
        </div>
    )
}

export default Error
import React from "react";
import "./Learnmore.css"
import "./StartPage.css"
import Consoltation from "./Consultation";
import QRCode from "react-qr-code";

const LearnMore = (props) => {
    const [displaymenu, setdisplaymenu] = React.useState(0);
 
    function swaptomenu(){
        setdisplaymenu(0);
    }

    const menu = (
    <div className='center '>
        <div className='colors outline column'>
            <div  className = 'title'>Learn More About Progenics</div>
            <div className = 'subtext'>Progenics Blood Cord Cryobank is dedicated to preserving the life-saving stem cells found in the umbillical cord</div>
            <div>
                <button className="button3" onClick={() => window.open('https://www.progenics.ca/', '_blank', 'noopener,noreferrer')}>Website</button>
                <button className="button3" onClick={() => {setdisplaymenu(1)}}>Free Consultation</button>
                <button className="button3" onClick={() => {props.onClose()}}>
                    <div >Back, or Scan the following QR Code for our website:</div>
                    <QRCode style={{padding: "15px"}} value='https://www.progenics.ca' bgColor="#dfdfdf"></QRCode>
                </button>
            </div>
        </div>
    </div>)

    const arr = [
        menu,
        <Consoltation onClose = {swaptomenu} error_response = {() => {props.error_response()}}></Consoltation>
    ]

   // console.log(arr[0])

    return (
        <div>
            {arr[displaymenu]}
        </div>
    );
}

export default LearnMore;

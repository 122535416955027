import subschema_contactinfo from './subschema/subschema_contactinfo'
import subschema_nameinfo from './subschema/subschema_nameinfo'
import subschema_locationinfo from './subschema/subschema_locationinfo'

const schema_emergencyinfo = {
    mandatory: false,
    format:{
        title: "Emergency Contact's Information",
        description: "(Optional)"
    },
    type: "section",
    entries:{
        name: {
            format: {
                alignment: 'horizontal',
                alignment_small: 'vertical',
                title: 'General Personal Info'
            },
            type: "section",
            entries: {
                firstAndMiddleName: subschema_nameinfo.entries.firstAndMiddleName,
                lastName: subschema_nameinfo.entries.lastName,
                race: {
                    label: "Race/Ethnic Background",
                    subtext: "(Required by Health Canada)",
                    type: 'text',
                    validation: {
                      required: 'Race/Ethnic Background is required',
                      pattern: {
                    },
                    },
                },
            },

        },
        location: subschema_locationinfo,
        contact: subschema_contactinfo
    }
}

export default schema_emergencyinfo;
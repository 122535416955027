import subschema_contactinfo from './subschema/subschema_contactinfo'
import subschema_nameinfo from './subschema/subschema_nameinfo'
import subschema_locationinfo from './subschema/subschema_locationinfo'

const schema_motherinfo = {
    mandatory: true,
    format:{
        title: "Mother's Information",
        description: "Parent 1"
    },
    type: "section",
    entries:{
        name: subschema_nameinfo,
        medical_timeinfo: {
            format: {
                title: 'Personal Info'
            },
            type: "section",
            entries: {
                race: {
                    label: "Race/Ethnic Background",
                    subtext: "(Required by Health Canada)",
                    type: 'text',
                    validation: {
                      required: 'Race/Ethnic Background is required',
                      pattern: {
                    },
                    },
                },
                expectency: {
                    label: 'Expected Due Date',
                    subtext: "(Required)",
                    type: 'date',
                    validation: {
                      required: 'Expected Due Date is required',
                      pattern: {
                      },
                    },
                    width: "20"
                },
                motherBirthDate: {
                    label: "Mother's Date of Birth",
                    subtext: "(Required)",
                    type: 'date',
                    validation: {
                      required: "Mother's Date of Birthis required",
                      pattern: {
                      },
                    },
                    width: "20"
                },
            }
        },
        location: subschema_locationinfo,
        contact: subschema_contactinfo
    }
}

export default schema_motherinfo;
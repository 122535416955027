import subschema_ynradioselect from './subschema/subschema_ynradioselect'

const schema_healthhistory = {
    mandatory: true,
    format:{
        title: "Health History",
        description: "For any “Yes” answers (except questions #2 and #3), please provide details under “Comments”. If you do not know the answers to any of the questions, you may contact Progenics for clarification or leave the answers blank. Our staff will contact you, if necessary, to obtain any missing answers."
    },
    type: "section",
    entries: {
        HIB_HB_HC: subschema_ynradioselect("HIB_HB_HC", "Do you have HIV, Hepatitis B (surface antigen), or Hepatitis C?"),
        isBio: subschema_ynradioselect("isBio","Are you the baby’s genetic (biological) mother?"),
        hasRegCheckup: subschema_ynradioselect("hasRegCheckup","During your pregnancy have you had regular check-ups with your doctor or midwife?"),
        pregIssues: subschema_ynradioselect("pregIssues","Have you had any problems with this pregnancy?"),
        prevPregIssues: subschema_ynradioselect("prevPregIssues","Have you had any problems with a previous pregnancy?"),
        takesMedication: subschema_ynradioselect("takesMedication","Are you taking any prescribed medications? If any, please indicate the name and dosage (please include any prenatal vitamins)"),
        hasSTI: subschema_ynradioselect("hasSTI","Do you currently have any sexually transmitted infections?"),
        fatherSiblingStatus: {
            format:{
                title: "Have you, your baby’s father, or your baby’s siblings ever:",
                alignment: 'horizontal',
                alignment_small: 'vertical',
                fontsize: 'larger'
            },
            type: "section",
            entries:{
                requireBlood: subschema_ynradioselect("requireBlood","Required chronic blood transfusions?", "fatherSiblingStatus"),
                hasImmunodeficiency: subschema_ynradioselect("hasImmunodeficiency","Been diagnosed with any inheritable deficiencies of the immune system, or inheritable predisposition (tendency) to infection?", "fatherSiblingStatus"),
                hasCancer: subschema_ynradioselect("hasCancer","Been diagnosed with any type of cancer (e.g., Leukemia)?" , "fatherSiblingStatus"),
            }
        },
        familyStatusDiseases: {
            format:{
                title: "Have you had, or are you aware of your baby’s father or any family having had, any of the following diseases or family traits? If any, please indicate the family member.",
                alignment: 'horizontal',
                alignment_small: 'vertical',
                fontsize: 'larger'
            },
            type: "section",
            entries:{
                hasAnemia: subschema_ynradioselect("hasAnemia","Required chronic blood transfusions?", "familyStatus"),
                hadSpleenRemoval: subschema_ynradioselect("hadSpleenRemoval","Been diagnosed with any inheritable deficiencies of the immune system, or inheritable predisposition (tendency) to infection?", "familyStatus")
            }
        },
        familyStatusHereditary: {
            format:{
                title: "Have you had, or are you aware of your baby’s father or any family having had, any of the following hereditary diseases or family traits? If any, please indicate the family member.",
                description: "(Optional)",
                alignment: 'horizontal',
                alignment_small: 'vertical',
                fontsize: 'larger'
            },
            type: "section",
            entries:{
                redBloodCellDisease: subschema_ynradioselect("redBloodCellDisease","Red blood cell diseases", "familyStatusHereditary"),
                whiteBloodCellDisease: subschema_ynradioselect("whiteBloodCellDisease","White blood cell/immune deficiencies ", "familyStatusHereditary"),
                plateletDisease: subschema_ynradioselect("plateletDisease","Platelet diseases", "familyStatusHereditary"),
                metabolicDisease: subschema_ynradioselect("metabolicDisease","Metabolic/storage disease", "familyStatusHereditary"),
                cjdPrionNeurologicalDisease: subschema_ynradioselect("cjdPrionNeurologicalDisease","Creutzfeldt-Jakob disease (CJD), prion-related disease, or a neurological disease with an unknown cause?", "familyStatusHereditary"),
                viralNeurologicalDisease: subschema_ynradioselect("viralNeurologicalDisease","A history of dementia or degenerative neurological disorder of viral or unknown cause?", "familyStatusHereditary"),
            }
        },
        personalMedicalHistory: {
            format:{
                title: "Have you:",
                alignment: 'horizontal',
                alignment_small: 'vertical',
                fontsize: 'larger'
            },
            type: "section",
            entries:{
                bloodDonorRefuse: subschema_ynradioselect("bloodDonorRefuse","Been refused as a blood donor or told not to donate blood?", "personalHistory"),
                hadCancerBloodDiseaseBleeding: subschema_ynradioselect("hadCancerBloodDiseaseBleeding","Had cancer, a blood disease or bleeding problem?", "personalHistory"),
                hadYellowJaundiceLiverDiseaseHepB: subschema_ynradioselect("hadYellowJaundiceLiverDiseaseHepB","Had yellow jaundice (excluding neonatal jaundice and jaundice secondary to mononucleosis), liver disease, hepatitis (after age 11), or a positive test for hepatitis B surface antigen (carrier)?", "personalHistory"),
                hadBabesiosisChagas: subschema_ynradioselect("hadBabesiosisChagas","Had babesiosis or Chagas disease?", "personalHistory"),
                hadDuraMaterTransplant: subschema_ynradioselect("hadDuraMaterTransplant","Had a dura mater transplant?", "personalHistory"),
                givenHumanPGH: {
                    type: 'section',
                        format: {
                            title: "Been given human-derived pituitary growth hormones within the following time frames?",
                            alignment: 'horizontal',
                            alignment_small: 'vertical',
                            fontsize: 'larger'
                        },
                        entries:{
                            pre1986CanadaUSA: subschema_ynradioselect("pre1986CanadaUSA","Prior to 1986, if treated in Canada or the USA?", "personalHistory_givenHumanPGH"),
                        }
                },
                takenTegisonPsoriasis: subschema_ynradioselect("takenTegisonPsoriasis","Taken Tegison for psoriasis?", "personalHistory"),
                hadHIVAIDS:subschema_ynradioselect("hadHIVAIDS","Had HIV/AIDS or a positive test for HIV/AIDS?", "personalHistory"),
                hadHemoMalignancyOrMelanoma:subschema_ynradioselect("hadHemoMalignancyOrMelanoma","Previously been diagnosed with a hematologic malignancy (e.g., Leukemia or Lymphoma) or with melanoma?", "personalHistory"),
                hadOrganTransplant:subschema_ynradioselect("hadOrganTransplant","Had an organ transplant?", "personalHistory"),
                hadTissueTransplantNonSelf:subschema_ynradioselect("hadTissueTransplantNonSelf","Had a tissue transplant from someone other than yourself?", "personalHistory"),
                hadEncephalisisOrMeningitisViralOrigin:subschema_ynradioselect("hadEncephalisisOrMeningitisViralOrigin","Had active encephalitis or meningitis of viral or unknown origin?", "personalHistory"),
                hadHLTV:subschema_ynradioselect("hadHLTV","Had HTLV-I or HTLV-II?", "personalHistory"),
                hadInfectionsHIVorHCVorHBVorSyphilis:subschema_ynradioselect("hadInfectionsHIVorHCVorHBVorSyphilis","Had a history of infection with HIV, clinically active HCV, clinically active HBV, and/or Syphilis? ", "personalHistory"),
                hadHDClottingConcentrates:subschema_ynradioselect("hadHDClottingConcentrates","Ever received human-derived clotting factor concentrates for hemophilia or related clotting disorders? ", "personalHistory"),
                hadMalaria:subschema_ynradioselect("hadMalaria","Had malaria?", "personalHistory"),
                lastThreeYearsMalariaRegions:subschema_ynradioselect("lastThreeYearsMalariaRegions","In the past 3 years, travelled to areas that are endemic for malaria (such as Africa, Southern and Southeast Asia, East Asia, South and Central America, Papua New Guinea, islands in the South Pacific, the Middle East, or Eastern Europe)?", "personalHistory"),
                last28DaysCentralSouthAmericaOrMexicoOrCaribbean:subschema_ynradioselect("last28DaysCentralSouthAmericaOrMexicoOrCaribbean","In the past 28 days, have you travelled to Mexico, the Caribbean, Central America or South America?", "personalHistory"),
                hadZikaDuringPregnancy:subschema_ynradioselect("hadZikaDuringPregnancy","Had any diagnosis of a Zika infection at any point during this pregnancy?", "personalHistory"),
                inZikaAreaDuringPregnancy:subschema_ynradioselect("inZikaAreaDuringPregnancy","Resided in, or travelled to an area with active Zika transmission at any point during this pregnancy? ", "personalHistory"),
                last12WeeksSmallpoxVaxContact:subschema_ynradioselect("last12WeeksSmallpoxVaxContact","In the past 12 weeks, had contact with someone who had a smallpox vaccination?", "personalHistory"),
            }
        },
        past14DaysCovidQuarantine: subschema_ynradioselect("past14DaysCovidQuarantine", "In the past 14 days, have you or anyone in your household, been asked to be placed under observation or quarantine by Public Health, due to a potential or confirmed case of COVID-19"),
        injuryMedicalHistory: {
            format:{
                title: "Have you:",
                alignment: 'horizontal',
                alignment_small: 'vertical',
                fontsize: 'larger'
            },
            type: "section",
            entries:{
                beenBittenByRabidAnimal: subschema_ynradioselect('beenBittenByRabidAnimal','In the past 6 months, had rabies or, been bitten by an animal and treated as if the animal were rabid','injuryMedicalHistory'),
                hadSexualContactMale: {
                    type: 'section',
                        format: {
                            title: "Been given human-derived pituitary growth hormones within the following time frames?",
                            alignment: 'horizontal',
                            alignment_small: 'vertical',
                            fontsize: 'larger'
                        },
                        entries: {
                            partnerHadZika: subschema_ynradioselect('partnerHadZika','Medical diagnosis of Zika infection? ','injuryMedicalHistory_hadSexualContactMale'),  
                            partnerInZikaArea: subschema_ynradioselect('partnerInZikaArea','Resided in, or travelled to, an area with active Zika transmission?','injuryMedicalHistory_hadSexualContactMale')
                        }
                },
                usedNasalCocaine: subschema_ynradioselect('usedNasalCocaine','Had a history of intranasal cocaine use?','injuryMedicalHistory'),
            }
        },
        past5YearsPersonalInfo: {
            format:{
                title: "In the past 5 years, have you",
                alignment: 'horizontal',
                alignment_small: 'vertical',
                fontsize: 'larger'
            },
            type: 'section',
            entries:{  
                usedNeedleRecreationalDrugs: subschema_ynradioselect('usedNeedleRecreationalDrugs','Used a needle for intravenous, intramuscular, or subcutaneous injection of drugs for nonmedical use?','past5YearsPersonalInfo'),
                didProstitution: subschema_ynradioselect('didProstitution','Had sex in exchange for money or drugs?','past5YearsPersonalInfo'),
                intercorseWithMaleWithMale: subschema_ynradioselect('intercorseWithMaleWithMale','Had sex with any male who has had sex with another male?','past5YearsPersonalInfo'),
            }
        },
        past12MonthsPersonalInfo: {
            format:{
                title: "In the past 12 months, have you: ",
                alignment: 'horizontal',
                alignment_small: 'vertical',
                fontsize: 'larger'
            },
            type: 'section',
            entries: {
                outsideCAorUSA: subschema_ynradioselect('outsideCAorUSA','Been outside Canada or the USA? If so, please specify the country and the travel dates.','past12MonthsPersonalInfo'),
                treatedIllnessFromTravel: subschema_ynradioselect('illnessFromTravel','Been treated for any travel-related illness after your return to Canada?','past12MonthsPersonalInfo'),
                recievedNonSelfBlood: subschema_ynradioselect('recievedNonSelfBlood','Received blood from someone other than yourself?','past12MonthsPersonalInfo'),
                closeContactYellowJuandiceOrHepatitisOrGivenHBIG: subschema_ynradioselect('closeContactYellowJuandiceOrHepatitisOrGivenHBIG','Had close contact with a person with yellow jaundice or clinically active viral hepatitis, or been given Hepatitis B Immune Globulin (HBIG)? (Note: Close contact includes living in the same household where sharing of kitchen and bathroom facilities occurs regularly).','past12MonthsPersonalInfo'),
                intercorseWithSuspectedHIVorHBVorHCVorSyphilis: subschema_ynradioselect('intercorseWithSuspectedHIVorHBVorHCVorSyphilis','Had sex with anyone who is suspected to have HIV, clinically active HBV, clinically active HCV or syphilis? ','past12MonthsPersonalInfo'),
                intercorseWithNeedleRecreationalDrugUser: subschema_ynradioselect('intercorseWithNeedleRecreationalDrugUser','Had sex with anyone who has used a needle for intravenous, intramuscular or subcutaneous injection of drugs for nonmedical use? ','past12MonthsPersonalInfo'),
                didProstitution: subschema_ynradioselect('didProstitution','Had sex in exchange for money or drugs?','past12MonthsPersonalInfo'),
                recieveShotsOrVaccinations: {
                    type: 'section',
                        format:{
                            alignment: 'horizontal',
                            alignment_small: 'vertical',
                            fontsize: 'larger'
                        },
                        entries:{
                            recieveAnyShots:  subschema_ynradioselect('recieveAnyShots','Received any shots or vaccinations? If any, please specify the type of shots or vaccinations, as well as the dates they were received on','past12MonthsPersonalInfo_recieveShotsOrVaccinations'),
                            recieveUnlicencedVaccination: subschema_ynradioselect('recieveUnlicencedVaccination','Received any unlicensed vaccinations? ','past12MonthsPersonalInfo_recieveShotsOrVaccinations'),
                        }
                },
                hadTattooOrPiercingOrAcupunctureOrNeedleInjury: subschema_ynradioselect('hadTattooOrPiercingOrAcupunctureOrNeedleInjury','Had a tattoo, ear or skin piercing, acupuncture, or an accidental needle stick injury?','past12MonthsPersonalInfo'),
                inJailOver72Hours: subschema_ynradioselect('inJailOver72Hours','Been an inmate of a correctional facility, jail or prison or been incarcerated for more than 72 consecutive hours? ','past12MonthsPersonalInfo'),
                exposedToHIVorHEPBorHEPCBlood: subschema_ynradioselect('inJailOver72Hours','Been exposed to blood known or suspected to be infected with HIV, Hepatitis B, and/or Hepatitis C through percutaneous inoculation (e.g., needlestick injury) or through contact with an open wound, non-intact skin, or mucous membrane?','past12MonthsPersonalInfo')                
            }
        },
        prionDisorderInfo: {
            format:{
                title: "Have you:",
                alignment: 'horizontal',
                alignment_small: 'vertical',
                fontsize: 'larger'
            },
            type: 'section',
            entries: {
                threeMonthsInUK1980to1996: subschema_ynradioselect('threeMonthsInUK1980to1996','Spent a total of 3 months or more in the United Kingdom (UK) from the beginning of 1980 through the end of 1996? ',"prionDisorderInfo"),
                fiveYearsInEurope: subschema_ynradioselect('fiveYearsInEurope','Spent a total of 5 years or more in Europe from 1980 to the present? ',"prionDisorderInfo"),
                threeMonthsInFrance1980to1996: subschema_ynradioselect('threeMonthsInFrance1980to1996','pent a total of 3 months or more in France from the beginning of 1980 through the end of 1996?',"prionDisorderInfo"),
                recieveBloodInEuropeAfter1980: subschema_ynradioselect('recieveBloodInEuropeAfter1980','Received any blood or blood component transfusions in the UK, France or elsewhere in Europe since 1980?',"prionDisorderInfo"),
                usedCattleInsulinAfter1980NoConfirmation: subschema_ynradioselect('usedCattleInsulinAfter1980NoConfirmation',' Injected bovine insulin since 1980, without confirmation that the product was not manufactured after 1980 from cattle in the UK?',"prionDisorderInfo")      
            }
        },
        past21DaysPersonalInfo: {
            format:{
                title: "In the last 21 days, have you:",
                alignment: 'horizontal',
                alignment_small: 'vertical',
                fontsize: 'larger'
            },
            type: 'section',
            entries: {
                hadDiagnosedInfection: subschema_ynradioselect("hadDiagnosedInfection","Had any diagnosed infections? ","past21DaysPersonalInfo"),
                hadFebrileIllness: subschema_ynradioselect("hadFebrileIllness","Had any undiagnosed febrile illness?","past21DaysPersonalInfo"),
                WNVDiagnosis: {
                    type: 'section',
                        format:{
                            alignment: 'horizontal',
                            alignment_small: 'vertical',
                            fontsize: 'larger'
                        },
                        entries:{
                            personalWNVDiagnosis:  subschema_ynradioselect('personalWNVDiagnosis','Been diagnosed with WNV (West Nile Virus)? ','past21DaysPersonalInfo_WNVDiagnosis'),
                            awareOfLocalWNVInfection: subschema_ynradioselect('awareOfLocalWNVInfection','Been aware of anyone in your geographical area diagnosed with a WNV infection?','past21DaysPersonalInfo_WNVDiagnosis'),
                    }
                },
            }
        }
    }
}

export default schema_healthhistory;
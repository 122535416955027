import React from "react";
import "./StartPage.css"


const NoInternet = (props) => {
    return (
        <div className = 'center'>
            <div className = 'colors outline column'>
                <div className = 'title'>Error</div>
                <div className = 'subtext'>
                    Unable to reach the server. Please connect to the internet and try again.
                </div>
                <button className="nextstepbutton" onClick = {() => {props.callback()}}>Reconnect</button>
            </div>
        </div>
    )
}

export default NoInternet
const subschema_nameinfo = {
    format: {
        alignment: 'horizontal',
        alignment_small: 'vertical',
        title: 'Name'
      },
      type: "section",
      entries: {
        firstAndMiddleName: {
          label: 'First & Middle Name(s)',
          subtext: '(as appears on health card)',
          type: 'text',
          validation: {
            required: 'First Name & Middle is required',
          },
        },
        lastName: {
          label: 'Last Name',
          subtext: '(as appears on health card)',
          type: 'text',
          validation: {
            required: 'Last Name is required',
          },
        },
      }
}

export default subschema_nameinfo
import React, { useState } from "react";
import "./StartPage.css";
import LearnMore from './LearnMore';

const StartPage = (props) => {
    const [showLearnMore, setShowLearnMore] = useState(false);
    const lmb = (                    <button className="learnmorebutton" onClick={() => setShowLearnMore(true)}>Learn More</button>)
    //learnmore temp removed to achieve release deadline
    return (
        <div className='center'>
            {showLearnMore ? (
                <LearnMore onClose={() => setShowLearnMore(false)} callback = {() => {setShowLearnMore(false)}} error_response = {() => {props.set_error()}}/>
            ) : (
                <div className='colors outline column'>
                    <div className='title'>Welcome to Progenics</div>
                    <div className='subtext'>
                        Thank you for choosing Progenics Blood Cord Cryobank. Please fill out the following forms outlining your health history and contact information to begin your registration.
                    </div>
                    <button className="nextstepbutton" onClick={() => { props.callback() }}>Start Your Registration</button>
                    {lmb}
                </div>
            )}
        </div>
    )
}

export default StartPage;

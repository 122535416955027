import {generate_checkbox_component_required} from './subschema/component_checkbox'

const schema_learnmore_phone = {
  mandatory: true,
    format:{
        title: "Phone",
        description: "Schedule a time for a Client Care Associate to contact you."
    },
    type: "section",
    entries: {
          CallDate: {
            label: 'Valid hours are 11am-3pm EST, Monday to Friday',
            type: 'datetime-local',
            validation: {
              required: "Required",
              validate: {
                restrict_time: (v) => {
                  var hour = new Date(v).getHours();
                  var day = new Date(v).getDate();
                  let bool_state = [0,6].includes(day) || (hour < 11 || hour > 15);
                  if (bool_state === true) return 'Invalid time. Valid hours are 11am-3pm EST, Monday to Friday'
                  else return
                  // console.log(hour)
                //  console.log(day)
                //  console.log(bool_state)
                }
              }
            },
            width: "20"
          },
    }
}

export default schema_learnmore_phone
import subschema_contactinfo from './subschema/subschema_contactinfo'
import subschema_nameinfo from './subschema/subschema_nameinfo'

const schema_spouseinfo = {
    mandatory: false,
    format:{
        title: "Spouse's Information",
        description: "Parent 2 (Optional)"
    },
    type: "section",
    entries:{
        name: subschema_nameinfo,
        medical_timeinfo: {
            format: {
                alignment: 'horizontal',
                alignment_small: 'vertical',
                title: 'Personal Info'
            },
            type: "section",
            entries: {
                race: {
                    label: "Race/Ethnic Background",
                    subtext: "(Required by Health Canada)",
                    type: 'text',
                    validation: {
                      required: 'Race/Ethnic Background is required',
                      pattern: {
                    },
                    },
                },
            }
        },
        contact: subschema_contactinfo,
    }
}

export default schema_spouseinfo;
const subschema_locationinfo = {
        format: {
            alignment: 'horizontal',
            alignment_small: 'vertical',
            title: 'Residential Information'
        },
        type: "section",
        entries: {
            address: {
                label: "Address",
                subtext: "(Required)",
                type: 'text',
                validation: {
                    required: 'Address is required',
                    pattern: {
                    }
                },
            },
            city: {
                label: "City",
                subtext: "(Required)",
                type: 'text',
                validation: {
                    required: 'City is required',
                    pattern: {
                    }
                },
            },
            province: {
                label: "Province/State",
                subtext: "(Required)",
                type: 'select',
                validation: {
                    required: 'Province/State is required',
                    pattern: {
                    }
                },
                options: [
                    'ON',
                    'QC',
                    'NS',
                    'NB',
                    'MB',
                    'BC',
                    'PE',
                    'SK',
                    'AB',
                    'NL'
                ]               
            },
            country: {
                label: "Country",
                subtext: "(Required)",
                type: 'text',
                validation: {
                    required: 'Country is required',
                    pattern: {
                    }
                },
            }

        }
}

export default subschema_locationinfo
const subschema_ynradioselect = (keyname, name, uppername) => {
  const tmp = {
    format: {
      alignment: 'horizontal',
      alignment_small: 'vertical',
      title: name
    },
    type: "section",
    entries:{

    }
  }
  tmp.entries['state'] = {
    //  label: 'Yes',
      subtext: '',
      type: 'radio',
      options: {
        yes:{
          label: 'Yes',
          subtext: '',
          validation:{
            required: ""
          }
        },
        no:{
          label: 'No',
          subtext: '',
          validation:{
            required: "Please Select an Option"
          }
        },
      }
  }
  tmp.entries['Comment'] = {
        label: 'Comments',
        subtext: '',
        type: 'text',
        validation: {
          //required: "testing"
        },
        custom_validation: {
        }
      }
  return tmp;
}

export default subschema_ynradioselect
import React from 'react'
import { useStopwatch, useTimer } from 'react-timer-hook';

import './reset_timer.css'
import './StartPage/StartPage.css'
const Reset_timer = () => {
    const [enable, setenable] = React.useState(false);

    function get_date(offset_mins){
        const date = new Date()
        date.setMinutes(date.getMinutes() + offset_mins)
        return date
    }

    function reset_timer(){
        timer.restart(get_date(15))
    }


    React.useEffect(() => {
        document.addEventListener('input', reset_timer)
        return () => document.removeEventListener('input', reset_timer)
    })

    const timer = useTimer({
        expiryTimestamp: get_date(15),
        onExpire: () => {setenable(true); timer2.restart(get_date(1), true)}
    });
    const timer2 = useTimer({
        autoStart: false,
        expiryTimestamp: get_date(),
        onExpire: () => window.location.reload()
    })

    const warning = (
        <div className='overlay-box '>
            <div className='center colors outline column'>
                <div className='title'>Are you still there?</div>
                <div className='subtext'>
                    You've been idle for 15 minutes. 
                    Press the button if you're still entering your form.
                </div>
                <div className='subtext'>The form will reset in: {timer2.totalSeconds} seconds</div>
                <button className='nextstepbutton' onClick = {() => {setenable(false); timer2.pause(); timer.restart(get_date(15))}}>I'm still here!</button>
            </div>
        </div>
    )

    React.useEffect(() => {
        if (enable === true){
        }
    }, [enable])

    return (
        <div>
            {(enable === true) && warning}
        </div>
    )


}

export default Reset_timer
import subschema_contactinfo from './subschema/subschema_contactinfo'
import subschema_nameinfo from './subschema/subschema_nameinfo'
import subschema_locationinfo from './subschema/subschema_locationinfo'
import {generate_checkbox_component} from './subschema/component_checkbox'

const schema_referralinfo = {
  mandatory: false,
    format:{
        title: "Referral Information",
        description: "(Optional)"
    },
    type: "section",
    entries: {
        referral: {
            format: {
              title: '',
              description: 'Please indicate any references, if any:'
            },
            type: "section",
            entries: {
              selfResearch: generate_checkbox_component('Self Research'),
              doctorMidwifeRecommend: generate_checkbox_component('Doctor/Midwife'),
              ultrasoundClinic: generate_checkbox_component('3D/4D Ultrasound'),
              googleReviews: generate_checkbox_component('Google Reviews'),
              socialMedia: generate_checkbox_component('Social Media'),
              hospitalNurseRecommend: generate_checkbox_component('Hospital/L&D Nurse'),
              prenatalClass: generate_checkbox_component('Prenatal Class'),
              babyShow: generate_checkbox_component('Baby Show'),
              returningClient: generate_checkbox_component('Returning Client'),                                  
            }
        }
    }
}

export default schema_referralinfo;
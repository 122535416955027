const schema_webpage = {
  format: {
    title: 'Register With Us',
    description: 'Please fill in the form to proceed. You will be contacted via email for verification purposes.'
  },
  type: "section",
  entries:{
        firstName: {
          label: 'First Name',
          subtext: '(Required)',
          type: 'text',
          validation: {
            required: 'First Name is required',
          },
        },
        lastName: {
          label: 'Last Name',
          subtext: '(Required)',
          type: 'text',
          validation: {
            required: 'Last Name is required',
          },
        },
        email: {
          label: 'Email',
          subtext: '(Required)',
          type: 'email',
          validation: {
            required: 'Email is required',
            pattern: {
              value: /^\S+@\S+$/i,
              message: 'Invalid email address',
            },
          },
        },
        phoneNumber: {
          label: 'Phone Number',
          type: 'tel',
          validation: {
            pattern: {
              value: /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/i,
              message: 'Invalid phone number. Example phone number +99 (123) 222 3333 4444',
            },
          },
        },
        /* //Possibly Redundant
        phoneNumber_Type: {
          label: 'Type',
          type: 'select',
          validation: {},
          options: [
            'Mobile',
            'Home',
            'Work',
          ]
        },*/
        zipCode: {
          label: 'Postal Code',
          type: 'text',
          validation: {
            pattern: {
              value: /^((\d{5}-\d{4})|(\d{5})|([A-Z|a-z]\d[A-Z|a-z]\d[A-Z|a-z]\d))$/i,
              message: 'Invalid zip code',
            },
          },
          width: "20"
        },
        expectency: {
          label: 'Expected Due Date',
          type: 'date',
          validation: {
            pattern: {
            },
          },
          width: "20"
        },
        hospital: {
          label: 'Hospital',
          type: 'text',
          validation: {
            pattern: {
            },
          },
          width: "20"
        },
  }
};
export default schema_webpage;
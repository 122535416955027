import React from "react";
import "./StartPage.css"


const Completed = (props) => {
    return (
        <div className = 'center'>
            <div className = 'colors outline column'>
                <div className = 'title'>Thanks For Registering</div>
                <div className = 'subtext'>
                    Thank you for registering at Progenics Blood Cord Cryobank. Our team will contact you to finalize your arrangement.
                </div>
                <button className="nextstepbutton" onClick = {() => {props.callback()}}>Start another registration</button>
            </div>
        </div>
    )
}

export default Completed
const subschema_contactinfo = {
        format: {
          alignment: 'horizontal',
          alignment_small: 'vertical',
          title: "Contact Information"
        },
        type: "section",

        entries: {
          email: {
            label: 'Email',
            subtext: '(Required)',
            type: 'email',
            validation: {
              required: 'Email is required',
              pattern: {
                value: /^\S+@\S+$/i,
                message: 'Invalid email address',
              },
            },
          },
          phoneNumber: {
            label: 'Phone Number',
            subtext: '(Required)',
            type: 'tel',
            validation: {
              required: 'Phone Number is required',
              pattern: {
                value: /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/i,
                message: 'Invalid phone number. Example phone number +99 (123) 222 3333 4444',
                },
            },
        },
    }
}

export default subschema_contactinfo;
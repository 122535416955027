import subschema_contactinfo from './subschema/subschema_contactinfo'
import subschema_nameinfo from './subschema/subschema_nameinfo'
import subschema_locationinfo from './subschema/subschema_locationinfo'

const schema_healthinfo = {
    mandatory: true,
    format:{
        title: "Healthcare Provider Information",
    },
    type: "section",
    entries:{
        genInfo: {
            format:{
                alignment: 'horizontal',
                alignment_small: 'vertical',
                title: 'Hospital Info'
            },
            type: "section",
            entries: {
                hospital: {
                    label: 'Delivery Hospital',
                    subtext: '(Required)',
                    type: 'text',
                    validation: {
                        required: 'Hospital name is required',
                    pattern: {
                    },
                    },
                    width: "20%"
                },
            residential:{
                format: {
                    alignment: 'horizontal',
                    alignment_small: 'vertical',
                    title: 'Residencial Information'
                },
                type: "section",
                entries: {
                    address: {
                        label: "Address",
                        type: 'text',
                        validation: {
                            
                            pattern: {
                            }
                        },
                    },
                    city: {
                        label: "City",
                        type: 'text',
                        validation: {
                           
                            pattern: {
                            }
                        },
                    },
                    province: {
                        label: "Province/State",
                    
                        type: 'select',
                        validation: {
                           
                            pattern: {
                            }
                        },
                        options: [
                            'ON',
                            'QC',
                            'NS',
                            'NB',
                            'MB',
                            'BC',
                            'PE',
                            'SK',
                            'AB',
                            'NL'
                        ]               
                    },
                    country: {
                        label: "Country",
                      
                        type: 'text',
                        validation: {
                    
                            pattern: {
                            }
                        },
                    }
        
                }
            }
        }
        }
    }
};

export default schema_healthinfo;
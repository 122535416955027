import React from 'react'
import Form from './FormStructure/Form';
import StartPage from './StartPage/StartPage';
import EmailVerification from './StartPage/EmailVerification';
import Completed from './StartPage/Completed';
import Error from './StartPage/Error';
import NoInternet from './StartPage/NoInternet';


import "./Home.css"

import schema_webpage from './schema/schema_webpage';//pass1 verified 


import schema_motherinfo from './schema/schema_motherinfo'; //pass1 verified
import schema_spouseinfo from './schema/schema_spouseinfo'; //pass1 verified

import schema_healthhistory from './schema/schema_healthhistory'; //pass2 verified
import schema_healthcare from './schema/schema_healthcare'; //pass3 verified
import schema_referralinfo from './schema/schema_referralinfo'; //pass3 verified
import schema_emergencyinfo from './schema/schema_emergencyinfo'; //pass3 verified
import schema_consent from './schema/schema_consent' //pass4 verified format, content needs updating. 



const formarr = {
    schema_webpage,
}

const formarr2 = {
    schema_motherinfo,
    schema_spouseinfo,
    schema_healthcare,
    schema_emergencyinfo,
    schema_referralinfo,
    schema_healthhistory,
    schema_consent
}
const combineddata = {
    initregistration: formarr,
    healthinfo: formarr2
}


const Home = () => {
    const [step, setStep] = React.useState(0);
    const [uid, setuid] = React.useState(null);
    const [submit_buffer, set_submit_buffer] = React.useState()
    //console.log(JSON.stringify(combineddata))
    const [formdata, setformdata] = React.useState(combineddata);

    async function getformdata(){
        await fetch ("https://infra-backend-gilt.vercel.app/app/" + "getschema", {
            method: "GET",
        })
        .then((res) => {
            return res.json()
        }).then((data) => {
          //  console.log(data)
            setformdata(data)
        })
        .catch((error)=>{
            setformdata(null)
//console.log(error)
        })
    }


    //React.useEffect(() => {getformdata()}, [])

    const increment = () => {
        if (step < steps.length){
            setStep(step+1);
        }
    }
    
    const decrement = () => {
        if (step > 0){
            setStep(step-1);
        }
    }


    const reset = () => {
        setStep(0);
        setuid(null);
        set_submit_buffer(null);
    }


    function update(data) {
        set_submit_buffer(prevState => ({
            ...prevState,
            schema_webpage: {
                ...prevState.schema_webpage,
                email: data
           }
        }))
    }

    //this could probably use a restructuring so all our submissions are done in a organized fashion
    function uid_get_callback(uid) {
        setuid(uid)
        transmit_bufdata(submit_buffer, '1', uid);
        increment()
    }


    function transmit_bufdata(databuf, step, uid){
        fetch("https://infra-backend-gilt.vercel.app/app/" + "submit", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({key: uid, step: step, data: databuf})
        })
        .then((res) => {
            return res.json()
        }).then((data) => {
          //  console.log(data)
        })
        .catch((error)=>{
            setStep(5)
           // console.log(error)
        })
    }

    //console.log(submit_buffer)
    let steps = null
    
    if (formdata !== null) steps = [  
        <StartPage callback = {increment} set_error = {() => {setStep(5)}} ></StartPage>,
        <Form sections = {formdata.initregistration} callbackprevious = {decrement} callback={(d) => {increment(); set_submit_buffer(d)}}></Form>,
        <EmailVerification forward={uid_get_callback} data={submit_buffer} updateData={update}></EmailVerification>,
        <Form sections = {formdata.healthinfo} callback={(d) => {increment(); transmit_bufdata(d,'2', uid)}}></Form>,
        <Completed callback = {reset}></Completed>,
        <Error callback= {reset}></Error>
    ];

   

    return (
        <div className = {"home " + ((step===0 || step ===4 || step === 5) && "startpage homecentered") + ' '+ ((step !== 0 && step !== 4 && step !== 5) && "questionpage")}>
           {formdata === null && <NoInternet callback = {getformdata}/>}
           {formdata !== null && steps[step]}
        </div>
    )

}

export default Home
import React from "react";
import Form from "../FormStructure/Form";
import schema_learnmore from "../schema/schema_learnmore";
import schema_learnmore_phone from "../schema/schema_learnmore_phone";
import Completed from "./Completed";

import "./StartPage.css";
import schema_consent from "../schema/schema_consent";


const Consoltation = (props) => {
    const [section, setsection] = React.useState(0);

    function submit_content(databuf){
        console.log(databuf)
        fetch("http://localhost:8000/app/" + "trialsubmit", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({data: databuf})
        })
        .then((res) => {
            console.log(res)
            return res.json()
        }).then((data) => {
            console.log(data)
            setsection(3)
        })
        .catch((error)=>{
            console.log(error)
           // props.error_response()
        })
    }

    const form = (
        <Form sections = {{email_contact: schema_learnmore, consent: schema_consent}} depthlevel = {2} callbackprevious = {() => {setsection(0)}} callback={(d) => {submit_content(d)}}></Form>
    )

    const menu = (
        <div className="colors outline column">
            <button className="nextstepbutton" onClick = {() => {setsection(2)}}>Phone</button>
            <button className="nextstepbutton" onClick = {() => {setsection(1)}}>Email</button>
            <button className="nextstepbutton" onClick = {() => props.onClose()}>Back</button>
        </div>
    )
//            <button className="nextstepbutton" onClick = {() => {}}>Online Chat</button>

    const phone = (
        <div>
            <a className = "submitbutton" href="tel:+14162211666">Call Now: 416-221-1666</a>
            <Form sections = {{phone_contact: schema_learnmore_phone}} depthlevel = {2} callbackprevious = {() => {setsection(0)}} callback={(d) => {submit_content(d)}}></Form>
        </div>
    )

    const done = (
            <div className = 'colors outline column'>
                <div className = 'titlesmall'>Thanks For Registering</div>
                <div className = 'subtext'>
                    Thank you for registering at Progenics Blood Cord Cryobank. Our team will contact you to finalize your arrangement.
                </div>
                <button className="nextstepbutton" onClick = {() => {props.callback()}}>Start another registration</button>
            </div>
    )

    const options = [
        menu,
        form,
        phone,
        done,
    ]

    return (
        <div className='center'>
            <div className="colors outline column">
             <div className='title'>Contact Us</div>
                <div className='subtext'>
                    Want to learn more about banking your baby's blood cord tissue? Please select from the following options.
                </div>
            {options[section]}
            </div>
        </div>
    )
}

export default Consoltation
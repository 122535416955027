import React from 'react'
import { ErrorMessage } from "@hookform/error-message"
import { useForm, useFormContext , useFormState} from 'react-hook-form'
import FormQuestionGeneric from './FormQuestionElements/FormQuestionGeneric'
import FormQuestionRadio from './FormQuestionElements/FormQuestionRadio'
import './QuestionStyle.css'


const FormQuestion = React.memo((props) => {
    //const {register, getFieldState, formState: {dirtyFields}, setValue, getValues} = useFormContext();
    const {formState, errors} = useFormState;

    //console.log(props.disabled)
    const questiondata = () => {
       // console.log(props.data.type)
        switch (props.data.type){
            case 'radio':
               return <FormQuestionRadio disabled={props.disabled} ID = {props.ID} data = {props.data} methods = {props.methods}></FormQuestionRadio>
            default:
               return <FormQuestionGeneric disabled={props.disabled} ID = {props.ID} data = {props.data} methods = {props.methods}></FormQuestionGeneric>
        }

       // return <input name={props.ID} {...props.methods.register}></input>
    }

    const geterrors = () => {
        return <ErrorMessage errors = {errors} name = {props.ID} render = {({message}) => <div className="question-error">{message}</div>}/>;
    }

    const question = () => {
        switch (props.data.type){
            case 'checkbox':
                return (<div className='question-row'>
                    <div className="question-query">
                        <div className='question-title'>{props.data.label}</div> 
                        <div className='question-subtitle'>{props.data.subtext}</div>
                    </div>
                    {questiondata()}
                </div>)
            default:
                return (<div>
                    <div className="question-query">
                        <div className='question-title'>{props.data.label}</div> 
                        <div className='question-subtitle'>{props.data.subtext}</div>
                    </div>
                    {questiondata()}
                </div>)
        }
    }

    return (
        <label className = {"question "}>
            {question()}
            {geterrors()}
        </label>
    )
}, (prevprops, nextprops) => {
    if (prevprops.methods.watch(prevprops.ID) !== nextprops.methods.watch(prevprops.ID)) return false
    if (prevprops.disabled != nextprops.disabled) return false
    return true
})
// 
export default FormQuestion
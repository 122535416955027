

const generate_checkbox_component = (name) => {
    return {
        label: name,
        type: 'checkbox',
        validation: {
          pattern: {
          },
        },
        }
}

const generate_checkbox_component_required = (name) => {
  return {
      label: name,
      type: 'checkbox',
      validation: {
        required: "This checkbox is required"
      },
      }
}

export {generate_checkbox_component, generate_checkbox_component_required}